import React from "react"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Heading from "../components/Layout/Heading"
import SubHeading from "../components/Layout/SubHeading"
import IncomingCars from "../components/IncomingCars"
import Spacer from "../components/Layout/Spacer"
import Icon from "../components/Icon"
import StockList from "../components/StockList"

const SouthNowraStock = ({ location, pageContext }) => {
  const { stock, incomingCars } = pageContext
  const dealership = "southnowra"

  return (
    <Layout location={location}>
      <SEO
        title="Used Vehicles | Solomon Motors City"
        description="Our selection of quality used vehicles located on the Princes Highway in South Nowra"
      />
      <Heading title="Used Vehicles" />
      <SubHeading subHeading="Solomon Motors City" />
      <IncomingCars dealership={dealership} incomingCars={incomingCars} />
      <Spacer />
      <StockList dealership={dealership} stockVehicles={stock} />
      <div className="row more-stock-container">
        <div className="col-12 text-center">
          <p>We have more cars available at Solomons Mid City Autos</p>
          <Link to="/used-vehicles/solomons-mid-city-autos">
            <Button variant="primary" bsPrefix="smg">
              View Stock <Icon prefix="fas" name="arrow-circle-right" />
            </Button>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default SouthNowraStock
