import React, { useState } from "react"
import Button from "react-bootstrap/Button"

import IncomingCarsForm from "../forms/IncomingCars"
import Heading from "../Layout/Heading"
import Icon from "../Icon"

import "./index.scss"

const IncomingCars = ({ dealership, incomingCars }) => {
  const [showModal, setShowModal] = useState(false)
  const [model, setModel] = useState("")
  const [description, setDescription] = useState("")

  const updateCarAndShowModal = (model, description) => {
    setShowModal(true)
    setModel(model)
    setDescription(description)
  }

  if (incomingCars.length > 0) {
    return (
      <>
        <IncomingCarsForm
          show={showModal}
          setShow={setShowModal}
          model={model}
          description={description}
          dealership={dealership}
        />
        <Heading title="Incoming Cars" />
        <div className="row">
          <div className="col text-center">
            <p>
              Below is a list of cars we are expecting at our dealership soon
            </p>
          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3">
          {incomingCars.map((incomingCar) => (
            <div className="col mb-4" key={incomingCar.id}>
              <div className="card">
                <div className="card-body">
                  <p className="incoming-cars-model">{incomingCar.model}</p>
                  <p className="incoming-cars-description">
                    {incomingCar.description}
                  </p>
                </div>
                <div className="card-footer text-center">
                  <Button
                    variant="primary"
                    bsPrefix="smg"
                    onClick={() =>
                      updateCarAndShowModal(
                        incomingCar.model,
                        incomingCar.description
                      )
                    }
                  >
                    More Info <Icon prefix="fas" name="arrow-circle-right" />
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    )
  } else {
    return ""
  }
}

export default IncomingCars
