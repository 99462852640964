import React, { useState, useEffect } from "react"
import Axios from "axios"
import { Link } from "gatsby"
import Button from "react-bootstrap/Button"

import Heading from "../Layout/Heading"
import Icon from "../Icon"
import Loader from "../Loader"

import car from "./icons/car.png"
import engine from "./icons/engine.png"
import transmission from "./icons/transmission.png"
import kms from "./icons/kms.png"
import pcs from "../../images/photos-coming-soon.jpg"

import "./index.scss"

const fileBucket = process.env.FILE_BUCKET
const API = process.env.API

const StockList = (props) => {
  const [stockVehicles, setStockVehicles] = useState(props.stockVehicles)
  const [sortByData, setSortByData] = useState()
  const [loading, setLoading] = useState(false)
  const dealership = props.dealership

  useEffect(() => {
    if (sortByData) {
      let res = sortByData.split(",")
      let sortBy = res[0]
      let dir = res[1]

      setLoading(true)
      Axios.get(`${API}/stock/${dealership}?sortBy=${sortBy}&dir=${dir}`).then(
        (response) => {
          setStockVehicles(response.data)
          setLoading(false)
        }
      )
    }
  }, [dealership, sortByData])

  if (stockVehicles.length > 0) {
    return (
      <>
        <Heading title="In Stock Now" />
        <div className="row">
          <div className="col text-center">
            <p>
              Below is a list of vehicles that are in stock and available for
              immediate delivery
            </p>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-lg-4 col-sm-6">
            <div className="form-group">
              <select
                className="form-control"
                onChange={(e) => setSortByData(e.target.value)}
              >
                <option value="stock_number,ASC">Sort By...</option>
                <option value="egc_price,ASC">Price: Low - High</option>
                <option value="egc_price,DESC">Price: High - Low</option>
                <option value="year,DESC">Year: New - Old</option>
                <option value="year,ASC">Year: Old - New</option>
                <option value="make,ASC">Make: A - Z</option>
                <option value="make,DESC">Make: Z - A</option>
              </select>
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
              {stockVehicles.map((stockVehicle) => {
                var vehicleLink = `${
                  stockVehicle.year
                }-${stockVehicle.make.replace(
                  /\s+/g,
                  "-"
                )}-${stockVehicle.model.replace(
                  /\s+/g,
                  "-"
                )}-${stockVehicle.derivative.replace(/\s+/g, "-")}/${
                  stockVehicle.stockNumber
                }`
                return (
                  <div className="col mb-4" key={stockVehicle.id}>
                    <div className="card">
                      {stockVehicle.images ? (
                        <Link to={vehicleLink} className="card-img-top">
                          <img
                            src={`${fileBucket}/images/${dealership}/${stockVehicle.stockNumber}_1_750.jpg`}
                            className="card-img-top"
                            alt=""
                            srcSet={`
                        ${fileBucket}/images/${dealership}/${stockVehicle.stockNumber}_1_1000.jpg 1000w,
                        ${fileBucket}/images/${dealership}/${stockVehicle.stockNumber}_1_750.jpg 750w,
                        ${fileBucket}/images/${dealership}/${stockVehicle.stockNumber}_1_500.jpg 500w,
                        ${fileBucket}/images/${dealership}/${stockVehicle.stockNumber}_1_300.jpg 300w
          `}
                            sizes="
                      (min-width: 992px) 33vw,
                      (min-width: 576px) 50vw,
                      100vw
                    "
                            role="presentation"
                            loading="lazy"
                          />
                        </Link>
                      ) : (
                        <Link to={vehicleLink} className="card-img-top">
                          <img
                            src={pcs}
                            className="card-img-top"
                            alt=""
                            role="presentation"
                            loading="lazy"
                          />
                        </Link>
                      )}
                      <div className="card-body">
                        <Link
                          to={vehicleLink}
                          className="vehicle-title"
                        >{`${stockVehicle.year} ${stockVehicle.make} ${stockVehicle.model} ${stockVehicle.derivative}`}</Link>
                        <br />
                        <span className="vehicle-price">{`$${stockVehicle.egcPrice.toLocaleString()}`}</span>
                        <div className="row">
                          <div className="col vehicle-description-container">
                            <p className="vehicle-description">
                              <img
                                className="vehicle-icon"
                                src={car}
                                alt="Car Type Description"
                              />{" "}
                              {stockVehicle.body}
                            </p>
                            <p className="vehicle-description">
                              <img
                                className="vehicle-icon"
                                src={engine}
                                alt="Car Engine Description"
                              />{" "}
                              {stockVehicle.engine}
                            </p>
                            <p className="vehicle-description">
                              <img
                                className="vehicle-icon"
                                src={transmission}
                                alt="Car Transmission Description"
                              />{" "}
                              {stockVehicle.transmission}
                            </p>
                            <p className="vehicle-description">
                              <img
                                className="vehicle-icon"
                                src={kms}
                                alt="Car Kilometres Description"
                              />{" "}
                              {`${stockVehicle.kilometres.toLocaleString()} km`}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer text-center">
                        <Link to={vehicleLink}>
                          <Button variant="primary" bsPrefix="smg">
                            More Details{" "}
                            <Icon prefix="fas" name="arrow-circle-right" />
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </>
    )
  } else {
    return (
      <div className="row">
        <div className="col-12 text-center">
          <span>No Vehicles to display</span>
        </div>
      </div>
    )
  }
}

export default StockList
